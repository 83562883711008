/* global  */ // tUrl等のグローバル変数を宣言する
import Swiper from 'swiper' // Swiper
// import PerfectScrollbar from 'perfect-scrollbar' // 独自スクロールバー
// position:styckyのポリフィル
// Stickyfill.add(document.querySelectorAll('.sticky'))

jQuery(function ($) {
  function trunk8() {
    $('.js-t8.line1').trunk8({
      // 指定要素に行数制限をかける
      lines: 1,
      fill: '...'
    })
  }
  if ($('.js-t8')[0]) {
    // 要素を検出したら実行
    trunk8()
    $(window).on('load resize ', function() {
      trunk8()
    })
  }

  /*
  ######  ##      ## #### ########  ######## ########
 ##    ## ##  ##  ##  ##  ##     ## ##       ##     ##
 ##       ##  ##  ##  ##  ##     ## ##       ##     ##
  ######  ##  ##  ##  ##  ########  ######   ########
       ## ##  ##  ##  ##  ##        ##       ##   ##
 ##    ## ##  ##  ##  ##  ##        ##       ##    ##
  ######   ###  ###  #### ##        ######## ##     ##
*/
  if ($('#s-staff_off_img')[0]) {
    // Swiperコンテナを検出したら実行
    // eslint-disable-next-line no-unused-vars
    const staffOffImgSwiper = new Swiper('#s-staff_off_img', {
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      preloadImages: false,
      autoplay: {
        delay: 3000
      }
    })
  }

  /*
#######  ###### ######   ######  ##      ##
##      ##      ##   ## ##    ## ##      ##
####### ##      ######  ##    ## ##      ##
     ## ##      ##   ## ##    ## ##      ##
#######  ###### ##   ##  ######  ####### #######
*/

  // スクロールナビゲーション
  const fps = 10 // FPS設定 60まで上げるとほとんど負荷低減効果がなくなる
  const frameTime = 1000 / fps
  let isScroll = false // スクロールフラグ
  let scrollY = 0 // 縦方向のスクロール量
  // 処理頻度テスト用
  // let scrollCount = 0
  // let actionCount = 0
  if (document.getElementById('scrollNav')) {
    document.addEventListener('scroll', function (ev) {
      // スクロール量の更新だけを行う
      scrollY = window.pageYOffset // スクロール量更新
      isScroll = true // フラグON
      // console.log('Scroll:' + ++scrollCount)
    })
    scrollNav()
  }
  function scrollNav() {
    // スクロールフラグが立っているときだけDOM書き換え
    if (isScroll) {
      $('.js-scrollNavPoint').each(function () {
        // 画面に収まった要素をactive
        let targetStart = $(this).offset().top // 画面上部からのターゲットの位置
        let targetEnd = targetStart + $(this).innerHeight() + $(window).innerHeight() // 画面下部からのターゲットの位置
        let scloll = scrollY + $(window).innerHeight() / 2 // ページ最上部からのスクロール量 + ビューポートの半分 = 画面の中間
        if (window.matchMedia('(min-width:769px)').matches) {
          scloll = scrollY + $(window).innerHeight() / 2 // ページ最上部からのスクロール量 + ビューポートの半分 = 画面の中間に走査線を張る
        } else {
          scloll = scrollY + $(window).innerHeight() // ページ最上部からのスクロール量 + ビューポートの半分 = 画面の下端に走査線を張る
        }

        if (scloll > targetStart && scloll < targetEnd) {
          // ターゲットの上端～下端に走査線が入っている
          if ($('.' + $(this).attr('id'))[0]) {
            // 対応するアイテムがある場合のみ実行
            $('.js-scrollNav').removeClass('active')
            $('.' + $(this).attr('id')).addClass('active')
          }
        }
      })
      isScroll = false // フラグをOFFに戻す
      // console.log('Action:' + ++actionCount)
    }
    setTimeout(scrollNav, frameTime) // 次回の処理を登録
  }
})
